@font-face {
    font-family: 'SF Pro Display';
    src: url('../public/Fonts/SFPRODISPLAYREGULAR.OTF') format('opentype');
    font-weight: normal;
    font-style: normal;
}
*{
    font-family:  SF Pro Display  !important;
}

.homebanner-bg {
  background-image: url("../public/assets/homeBanner.png");
  width: 100%;
  height: 80vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.homebanner-bg2 {
  background-image: url("../public/assets/V\ banner\ 2.jpg");
  width: 100%;
  height: 80vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner-content h1 {
  font-size: 40px;
  font-weight: 400;
  color: #ffffff;
}
.banner-content h1 span {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
}
.banner-con-height {
  height: 80vh;
}
.phone-img {
  width: 47px;
}
.laptop-img {
  width: 77px;
}
.cam-img {
  width: 95px;
}
.hphone-img {
  width: 77px;
}
.ac-img {
  width: 100px;
}
.phone-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
}
.prod-title {
  font-size: 18px;
  font-weight: 500;
}
.sm-product {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .phone-img {
    width: 25px;
  }
  .laptop-img {
    width: 40px;
  }
  .cam-img {
    width: 50px;
  }
  .hphone-img {
    width: 35px;
  }
  .ac-img {
    width: 50px;
  }
  .phone-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .prod-title {
    font-size: 10px;
    font-weight: 500;
  }
  .sm-product {
    justify-content: space-between;
    padding: 0px 10px;
  }
}
.title {
  font-size: 25px;
  font-weight: 700;
  color: #1f211e;
}
.para {
  font-size: 16px;
  font-weight: 400;
  color: #1f211e;
  text-indent: 40px;
}
.pro-img {
  width: 560px;
}
.icon-div {
  width: 50%;
}
.icon-div img {
  width: 56px;
}
.focus {
  width: 43%;
}
/* .focus img{
  width: 50px !important;
} */
.company-con h3 {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}
.company-con p {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.expertise-img {
  width: 260px;
}
.list-ta ul li {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  padding: 5px 0px;
}
.carousl-img {
  width: 193px;
}
.address-H {
  width: 50%;
}
.address-H h4 {
  font-size: 20px;
  font-weight: 500;
}
.address-E {
  width: 25%;
}
.address-E h4 {
  font-size: 20px;
  font-weight: 500;
}
.address-P {
  width: 25%;
}
.address-P h4 {
  font-size: 20px;
  font-weight: 500;
}
.para2 {
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  color: #000;
}
.form-name {
  background-color: #ededed;
  border: none;
  width: 100%;
  height: 35px;
}
.form-phone {
  background-color: #ededed;
  border: none;
  width: 100%;
  height: 35px;
}
.textarea {
  background-color: #ededed;
  border: none;
  width: 100%;
}
.label {
  font-size: 16px;
  font-weight: 500;
  padding: 5px 0px;
}
.form-btn {
  padding: 5px 30px;
  border: 1px solid #000;
  background-color: transparent;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
}
.social-media {
  width: 30px;
}
.logo {
  width: 200px;
}
.f-para {
  font-size: 16px;
  font-weight: 500;
}
.footer-sec {
  box-shadow: 0px -2px 4px 0px #00000040;
}
.quick-link {
  display: flex;
  justify-content: center;
}
.nav-product h3 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}
.nav-product ul li {
  list-style-type: none;
  padding: 3px 0px;
}
.nav-product ul li a {
  font-size: 14px;
  font-weight: 500;
  color: #757575;
  list-style-type: none;
  text-decoration: none;
}

.loc-img {
  width: 25px;
}
.loc-img img {
  width: 20px;
}
.loc {
  width: 50px !important;
}
.our-prod {
  display: flex;
  justify-content: center;
}
.plus{
  font-size: 22px;
  font-weight: 700;
}
@media (max-width: 768px) {
  .our-prod {
    display: block;
    justify-content: center;
  }
  .quick-link {
    display: flex;
    justify-content: start;
  }
  .contact-sm{
    padding-top: 20px;
  }
}
.F-para {
  font-size: 14px;
  font-weight: 500;
  color: #757575;
}

.happy-icon img {
  width: 50px;
}
.line {
  height: 30px;
  width: 1px;
  background-color: #000;
}
.happy h1 {
  font-size: 30px;
  font-weight: 700;
}
.happy p {
  font-size: 16px;
  font-weight: 400;
}
@media (max-width: 992px) {
  .happy h1 {
    font-size: 20px;
    font-weight: 600;
  }
  .happy p {
    font-size: 14px;
    font-weight: 400;
  }
  .happy-icon {
    /* display: none; */
  }
}
.hiring h1 {
  font-size: 35px;
  font-weight: 700;
}
.hiring p {
  font-size: 16px;
  font-weight: 400;
  color: #1f211e;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-upload-wrapper
  .ant-upload-drag {
  border-style: dashed !important;
  border-radius: 0px !important;
  background: #fff !important;
}
.hire-img {
  width: 560px;
}

/* header */

.header-logo {
  width: 200px;
}
.bg-body-tertiary {
  background-color: #fff !important;
}
#responsive-navbar-nav {
  /* display: flex !important; */
  justify-content: end !important;
  align-items: center !important;
}
.head-nav {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #fff;
  text-decoration: none;
}
.head-nav:hover {
  text-decoration-thickness: 2px;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 992px) {
  .hover-dropdown {
    display: none;
  }
  .lg-hide {
    display: block !important;
  }
}
.hover-dropdown {
  width: 38%;
  height: auto;
  background-color: aqua;
  position: absolute;
  bottom: -250px;
  padding: 1.5rem;
  z-index: 99;
  border-radius: 5px;
  opacity: 0;
}
.service-H:hover .polygon-Header {
  opacity: 1 !important;
}
.polygon-Header {
  opacity: 0;
}
.head-li li {
  list-style-type: none;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  padding: 10px 10px 10px 20px;
}
.head-li li:hover {
  /* background: linear-gradient(to right, #318a9a, #5ac298); */
  cursor: pointer;
  color: #000 !important;
}
.bg-body-tertiary {
  display: none;
}

.main-header {
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
/* @media only screen and (min-width: 1200px) {
  .main-header .container {
    max-width: 1555px;
  }
} */
.main-header .container-fluid {
  padding-left: 25px;
  padding-right: 25px;
}
@media only screen and (min-width: 1200px) {
  .main-header .container-fluid {
    padding-left: 55px;
    padding-right: 55px;
  }
}
.main-header .header-upper {
  z-index: 5;
  width: 100%;
  position: relative;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.main-header .header-upper1 {
  z-index: 5;
  width: 100%;
  position: relative;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.main-header .logo-outer {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}
@media only screen and (max-width: 991px) {
  .main-header .logo-outer {
    display: none;
  }
}
.main-header .logo {
  z-index: 9;
  padding: 2px 0;
  position: relative;
  margin-right: 180px;
}
.main-header.menu-absolute .header-upper {
  position: absolute;
}
.main-header.menu-absolute .header-upper1 {
  position: absolute;
}
.main-header.fixed-header .header-upper {
  top: 0;
  left: 0;
  position: fixed;
  background: white;
  -webkit-animation: sticky 1s;
  animation: sticky 1s;
  box-shadow: 0px 2px 4px 0px #00000040;
}
.main-header.fixed-header .header-upper1 {
  top: 0;
  left: 0;
  position: fixed;
  background: white;
  -webkit-animation: sticky 1s;
  animation: sticky 1s;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
}

@media only screen and (min-width: 992px) {
  .main-header.fixed-header .main-menu .navbar-collapse > ul > li {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header-top-wrap {
    display: none;
  }
}

.header-top {
  font-size: 18px;
  font-weight: 500;
}
.header-top .top-left > ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.header-top .top-left > ul li:not(:last-child) {
  margin-right: 33px;
}
.header-top .top-left > ul li a:hover {
  text-decoration: underline;
}
.header-top .top-right > ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.header-top .top-right > ul > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-top .top-right > ul > li > i {
  margin-right: 5px;
}
.header-top .top-right > ul > li a {
  text-decoration: underline;
}
.header-top .top-right > ul > li:not(:last-child):after {
  content: "";
  margin-left: 25px;
  margin-right: 25px;
  width: 5px;
  height: 5px;
  background: white;
  line-height: 5px;
  border-radius: 50%;
  text-align: center;
}
.header-top .top-right .nice-select {
  border: none;
  padding: 0 15px 0 0;
  background-color: transparent;
}
.header-top .top-right .nice-select:after {
  right: 0;
  border-color: white;
}
.header-top .top-right .nice-select .list {
  width: auto;
}
.header-top .top-right .nice-select .list li {
  color: #696e7b;
}
.header-top .top-right .nice-select .current {
  color: white;
  font-weight: 500;
  text-decoration: underline;
}

.nav-outer {
  margin-left: auto;
}
@media only screen and (max-width: 991px) {
  .nav-outer {
    width: 100%;
  }
}

/** Header Main Menu **/
@media only screen and (max-width: 991px) {
  .main-menu {
    width: 100%;
  }
}

.main-menu .mobile-logo {
  margin-right: auto;
}
@media only screen and (max-width: 575px) {
  .main-menu .mobile-logo {
    max-width: 150px;
  }
}

@media only screen and (max-width: 991px) {
  .main-menu .collapse {
    overflow: auto;
  }
}

.main-menu .navbar-collapse {
  padding: 0px;
}
.main-menu .navbar-collapse > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse > ul {
    display: block;
    padding: 25px 0;
    background: #ffffff;
  }
  .main-menu .navbar-collapse > ul > li:last-child {
    border-bottom: 1px solid #f3f3f3;
  }
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse {
    left: 0;
    width: 100%;
    position: absolute;
  }
}
.main-menu .navbar-collapse li {
  padding: 20px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media only screen and (max-width: 1399px) {
  .main-menu .navbar-collapse li {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li {
    display: block;
    padding: 0 15px;
    border-top: 1px solid #f3f3f3;
  }
}
.main-menu .navbar-collapse li.dropdown .dropdown-btn {
  cursor: pointer;
  font-size: 12px;
  margin-left: 5px;
  color: #293043;
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li.dropdown .dropdown-btn {
    position: absolute;
    right: 10px;
    top: 0;
    width: 50px;
    height: 43px;
    border-left: 1px solid #f2f2f2;
    text-align: center;
    line-height: 43px;
  }
}
.main-menu .navbar-collapse li a {
  text-decoration: none;
  display: block;
  font-size: 16px;
  opacity: 1;
  font-weight: 500;
  position: relative;
  color: #293043;
  text-transform: capitalize;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li a {
    padding: 10px 10px;
    line-height: 22px;
  }
}
.main-menu .navbar-collapse li a:hover {
  color: #0066ff;
  text-decoration: underline;
}
.main-menu .navbar-collapse li.current > a,
.main-menu .navbar-collapse li.current-menu-item > a {
  font-weight: 500;
}

.main-menu .navbar-collapse li li a {
  text-transform: capitalize;
}
.main-menu .navbar-collapse li li a:before {
  display: none;
}
.main-menu .navbar-collapse li .megamenu {
  position: absolute;
  left: 0px;
  top: 100%;
  width: 100%;
  z-index: 100;
  display: none;
  padding: 20px 0;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}
.main-menu .navbar-collapse li .megamenu:after {
  display: block;
  clear: both;
  content: "";
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li .megamenu {
    position: relative;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
  }
  .main-menu .navbar-collapse li .megamenu .container {
    max-width: 100%;
  }
  .main-menu .navbar-collapse li .megamenu .row {
    margin: 0px;
  }
}
.main-menu .navbar-collapse li .megamenu ul {
  display: block;
  position: relative;
  top: 0;
  width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.main-menu .navbar-collapse li ul {
  position: absolute;
  left: inherit;
  top: 110%;
  min-width: 468px;
  z-index: 100;
  left: -20px;
  display: none;
  border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}
.polygon-Header {
  position: absolute;
  left: 40px;
  top: 79%;
  text-align: center;
  z-index: 1000;
}
.polygon-Header img {
  width: 20px;
}

@media only screen and (max-width: 991px) and (max-width: 375px) {
  .main-menu .navbar-collapse li ul {
    min-width: auto;
  }
}
.main-menu .navbar-collapse li ul li {
  width: 100%;
  padding: 7px 20px;
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li ul li {
    padding: 0 15px;
  }
}
.main-menu .navbar-collapse li ul li ul {
  left: 100%;
  top: 0%;
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li ul li ul {
    left: auto;
  }
}

/* Menu White */
.main-header.header-two {
  position: absolute;
}
@media only screen and (min-width: 992px) {
  .main-header.header-two .nav-outer {
    margin-left: 75px;
  }
}
.main-header.header-two .nav-search > button {
  color: white;
}
@media only screen and (min-width: 992px) {
  .main-header.header-two .main-menu .navigation > li > a,
  .main-header.header-two .main-menu .navigation > .dropdown > .dropdown-btn {
    color: #000;
  }
}
.main-header.header-two .main-menu .navigation li li a:hover {
  color: #000;
}
.main-header.header-two .menu-sidebar button .icon-bar,
.main-header.header-two .main-menu .navbar-toggle .icon-bar {
  background: rgb(255, 252, 252);
}
.main-header.header-two .menu-sidebar button .icon-bar1,
.main-header.header-two .main-menu .navbar-toggle .icon-bar1 {
  background: rgb(221, 150, 19);
}
.main-header.header-two .menu-btns .theme-btn.style-three:not(:hover) {
  border-color: white;
}
.main-header.header-two.fixed-header .header-upper {
  background: #fff !important;
}

.header-lg-dropdown {
  padding-left: 0px;
}

.nav-link {
  color: #fff !important;
}
.dropdown:hover .dropdown li a {
  text-decoration: underline !important;
}
.home:hover {
  text-decoration: underline !important;
}
.head-li li a:hover {
  text-decoration: none !important;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 20px;
  background-color: transparent;
  transition: background-color 0.3s ease;
  z-index: 1000;
}

.header.scrolled {
  background-color: rgba(255, 255, 255);
}

h1 {
  color: white;
}
.servicebd-color {
  background-color: rgba(0, 0, 0, 0.6) !important;
  padding: 0.6rem !important;
}
.lg-hide {
  display: none !important;
}
.margin {
  margin-top: 4rem;
}
.contact-show {
  display: block;
}
@media (max-width: 992px) {
  .lg-hide {
    display: block !important;
  }
  .contact-show {
    display: none;
  }
}
.list-li li a {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #181818 !important;
}
.drop-title {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #181818 !important;
  padding-left: 20px;
}
.contact-btn {
  background-color: transparent;
  border: 1px solid #000;
  padding: 5px 20px;
  border-radius: 5px;
  text-decoration: none;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.m-header {
  position: fixed !important;
  box-shadow: 0px 2px 4px 0px #00000040;
  z-index: 2;
}
.welcome-para {
  font-size: 16px;
  font-weight: 400;
  color: #1f211e;
}
.stroke {
  font-size: 25px;
  font-weight: 700;
  color: white;
  -webkit-text-stroke: 1px black;
  text-stroke: 1px black;
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000;
}
.h3-title {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}
.num {
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
}
.num h3 {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 700;
}
.stroke-active {
  font-size: 25px;
  font-weight: 700;
  color: white;
  -webkit-text-stroke: 1px black;
  text-stroke: 1px black;
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.earn h3 {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.earn p {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.tick-icon {
  padding-top: 4px;
  margin-right: 15px;
}
.ul-tick ul li {
  font-size: 17px;
  font-weight: 400;
  color: #1f211e;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 10px;
}
.profile {
  border: 1px solid #000;
  width: 167px;
  height: 167px;
  box-shadow: 0px 0px 50px 3px #a1a1a180;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile img {
  margin-bottom: -18px;
}
.prof-detail h5 {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}
.prof-detail p {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.hire-bg {
  background-image: url("../public/assets/Rectangle\ 325.png");
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 50vh;
}
.hire-col {
  height: 50vh;
  color: #fff;
}
.mail {
  text-decoration: none;
  color: #757575;
}
.test-bg {
  background-image: url("../public/assets/testni.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 510px;
  position: relative;
}
.test-img1 {
  width: 276px;
  position: absolute;
  top: 50px;
}
.test-img2 {
  width: 249px;
  position: absolute;
  bottom: 20px;
  left: 22%;
}
.test-img3 {
  width: 279px;
  position: absolute;
  top: 100px;
  left: 45%;
}
.home-banner-lg{
  display: block;
}
@media (max-width: 992px) {
  .test-img1 {
    width: 276px;
    position: static;
    top: 50px;
  }

  .test-img2 {
    width: 276px;
    position: static;
    bottom: 20px;
    left: 22%;
    padding: 18px 0px;
  }
  .test-img3 {
    width: 276px;
    position: static;
    top: 100px;
    left: 45%;
  }
  .test-bg {
    height: auto;
    padding: 20px 0px;
  }
  .set {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
.homebannerS-bg {
  background-image: url(".././public/assets/s-banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: none;
}
.address-title{
  width: 20%;
  font-size: 20px;
  font-weight: 500;
  color: #231F20;
}
.lg-ban{
  display: block;
}
.sm-ban{
  display: none;
}
.sm-view{
  display: block;
}
.lg-view{
  display: none;
}

@media (max-width: 768px) {
  .homebannerS-bg {
    background-image: url(".././public/assets/s-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    background-position: center;
    height: 193px;
  }
  .homebanner-bg {
    display: none;
  }
  .sm-banner-text h1 {
    font-size: 20px;
    width: 80%;
  }
  .sm-banner-text h1 span {
    font-size: 30px;
  }
  .sm-banner-text {
    height: 193px;
  }
  .home-banner-lg{
    display: none;
  }
  #sm-product{
    padding: 10px 0px !important;
  }
  #sm-product2{
    padding: 30px 0px 0px 0px !important;
  }
  .address-title{
    width: 25%;
  }
  .lg-ban{
    display: none;
  }
  .sm-ban{
    display: block;
  }
  .sm-view{
    display: none;
  }
  .lg-view{
    display: block;
  }
}
.gif-sec {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gif-sec img {
  width: 300px;
}
.slick-prev,
.slick-next {
  display: none !important;
}
.con-ban {
  width: 70%;
}
.con-ban2 {
  width: 50%;
}
.con-ban2 h1 {
  color: #000;
  font-size: 60px;
  font-weight: 700;
  line-height: 40px;
}
.con-ban2 h1 span {
  color: #000;
  font-size: 35px;
  font-weight: 700;
}
.copyrights p{
  font-size: 14px;
  font-weight: 700;
  color: #5A5A5A;
  text-align: center;
}
.icons-can{
  width: 20% !important;
}
.cus-c{
  font-size: 16px;
  font-weight: 700;
}
.box-login h2{
  font-size: 25px;
  font-weight: 700;
}
.box-login p{
  font-size: 14px;
  font-weight: 400;
  color: #231F20;
}
.box-login label{
  font-size: 16px;
  font-weight: 400;
  color: #231F20;
}
.box-login input{
  width: 100%;
  border-radius: 5px;
  background-color: #EDEDED;
  border: 1px solid #EDEDED;
  padding: 4px;
}
.box-login button{
  background-color: #231F20 !important;
  width: 150px !important;
  height: 40px !important;
  border-radius: 5px !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-otp {
  column-gap: 30px !important;
}
@media(max-width:768px){
  .css-3nwxr6{
    width: 350px !important;
  }
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-checkbox-checked .ant-checkbox-inner{
  background-color: #181818 !important;
  border-color: #181818 !important;
}
